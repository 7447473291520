import { BlueLake } from '../models/bluelake-models';

export const products: BlueLake.Product[] = [
  {
    id: '579d51a6-c27b-433b-9a8c-07d6982cddd3',
    name: 'Compliancy',
    material_icon: 'verified_user',
    shortName: 'Compliancy',
    shortDescription:
      'Take control of your Azure environment. Stay compliant and secure with our Compliancy dashboard',
    description:
      'The Compliancy dashboard offers a comprehensive view of Azure compliance and security, as well as industry defined configuration compliancy checks. This centralized solution scans the Azure environment and evaluates defined rules against your environment, including both custom rules and known compliancy frameworks. The dashboard allows you to verify that you are operating within a compliant architecture, making it a valuable tool for ensuring the security and integrity of your Azure environment.',
    iconUrl: 'compliancy.png',
    features: [
      'Combined view of all relevant security scores (Azure Secure Score, Microsoft secure Score, Regulatory scores) for all scoped subscriptions',
      'Blue Ocean Configuration Compliancy: Set of configuration checks to check if your Azure environment is compliant with fast moving industry best',
      'Export affected resources:Easy export of affected resources in table format',
    ],
    relatedProducts: [],
    screenshotUrls: ['compliancy_laptop.png'],
    accessRights: {
      rbacRoles: [
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Reader',
        },
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName:
            '(Optional, but required for Microsoft Secure Score insights) Entra - SecurityActions.Read.All',
        },
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName:
            '(Optional, but required for Microsoft Secure Score insights) Entra - SecurityAlert.Read.All',
        },
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName:
            '(Optional, but required for Microsoft Secure Score insights) Entra - SecurityEvents.Read.All',
        },
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName:
            '(Optional, but required for Microsoft Secure Score insights) Entra - SecurityIncident.Read.All',
        },
      ],
      azureAdRoles: [],
    },
  },
  {
    id: 'f74349e6-addb-410c-a25d-5a7d024fac27',
    name: 'Vulnerability',
    material_icon: 'crisis_alert',
    shortName: 'Vulnerability',
    shortDescription:
      'Scan, check, and secure: Keep your Azure endpoints free of vulnerabilities',
    description:
      "This simulation allows for the execution of a recon process similar to that of a hacker. By mapping Common Vulnerabilities and Exposures (CVEs) to Endpoint Protection System Solutions (EPSS), effective remediation of vulnerabilities can be achieved. The vulnerability dashboard includes functionality to scan an Azure environment's endpoints for known vulnerabilities. It compiles a list of CVE vulnerabilities and cross-checks them with the endpoints retrieved from the scanned environment.",
    iconUrl: 'vulnerability.png',
    features: [
      'CVSS 3 details',
      "EPSS score that is mapped to assist in prioritizing the fixing of CVE's",
      "EPSS score for assisting in prioritizing the fixing of CVE's",
    ],
    relatedProducts: [],
    screenshotUrls: ['vulnerability_laptop.png'],
    accessRights: {
      rbacRoles: [
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Reader',
        },
      ],
      azureAdRoles: [],
    },
  },
  {
    id: 'a97e0610-796f-4acb-a22d-5f3eb06f1870',
    name: 'Identity',
    material_icon: 'group',
    shortName: 'Identity',
    shortDescription:
      'Gather insights on your identity setup. Always be aware of notable risks and expiring secrets',
    description:
      'The dashboard provides users with insights into their Azure applications and service principals, enabling them to quickly identify and respond to potential security threats, as well as take proactive steps to secure their resources. It helps to keep user, group, and identity creation in Azure AD under control. Additionally, this dashboard identifies sensitive permissions on applications and provides an overview of certificate and secret expirations.',
    iconUrl: 'identity.png',
    features: [
      'Overview of expired/soon to be expired certificates and secrets',
      'Allows users to quickly navigate to certificate and secrets details to perform a clean up',
      'Detect applications with missing owner',
    ],
    relatedProducts: [],
    screenshotUrls: ['identity_laptop.png'],
    accessRights: {
      rbacRoles: [],
      azureAdRoles: [
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Entra - Application.ReadAll',
        },
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Entra - RoleManagement.Read.Directory',
        },
      ],
    },
  },
  {
    id: '1300aa23-1603-46fc-a795-dc95fa2ab832',
    name: 'Infrastructure',
    material_icon: 'build_circle',
    shortName: 'Infrastructure',
    shortDescription:
      'Continuously assess the configuration of your virtual machine landscape and get insights on orphaned resources for potential cleanup',
    description:
      'This operational dashboard is designed to monitor and resolve issues with virtual machines (VMs) in Azure. It allows you to track key metrics of your IaaS cloud resources throughout your environment. With this dashboard, your operational teams can easily assess the configuration of agents, backups, and updates for your virtual machines, enabling them to quickly resolve any potential problems.',
    iconUrl: 'IaaS.png',
    features: [
      'BackUp Insights',
      'Update Insights',
      'Protection Insights',
      "Issue report: quick overview of all VM's with potential issues",
      'Orphaned resources view',
      "Unique traffic light view on VM status with a possibility to exclude VM's on approved workloads",
    ],
    relatedProducts: [],
    screenshotUrls: ['iaas_laptop.png'],
    accessRights: {
      rbacRoles: [
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Reader',
        },
      ],
      azureAdRoles: [],
    },
  },
  {
    id: '5e6933bb-a7d3-4d73-b309-a99dd8eb6073',
    name: 'Kubernetes',
    shortName: 'Kubernetes',
    material_icon: 'layers',
    shortDescription:
      'Get the full picture of your Kubernetes environment. Maintain healthy version lifecycles on your clusters',
    description:
      'This operations dashboard provides users with insights into their Azure Kubernetes environment. It aims to display the status of all clusters in the scanned environment, along with a list of open-source security vulnerabilities. This information is cross-checked with the retrieved cluster information to provide a comprehensive overview of the Kubernetes environment.',
    iconUrl: 'kubernetes_aks.png',
    features: [
      'RBAC visualizer',
      'Integrating well known tooling with Microsoft baseline.',
      'Helm Updates',
      'Deprecation Warnings',
      'Node CVE Scanner',
    ],
    relatedProducts: [],
    screenshotUrls: ['kubernetes_laptop.png'],
    accessRights: {
      rbacRoles: [
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Reader',
        },
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Kubernetes Cluster Reader',
        },
      ],
      azureAdRoles: [],
    },
  },
  {
    id: 'b627623a-a6a7-467c-9f5d-c758960b1a65',
    name: 'Cost',
    material_icon: 'paid',
    shortName: 'Cost',
    shortDescription:
      'Gain valuable insights into your cloud usage and spending patterns',
    description:
      'Take control of your Azure Cloud spending with our powerful Cloud Cost Optimization Insights dashboard. Gain valuable insights into your cloud usage and spending patterns, identify areas of inefficiency and waste, and reduce your overall cloud costs. Our dashboard provides data on your cloud usage and costs, empowering you to make informed decisions and take proactive steps to optimize your spending. With actionable recommendations, you can quickly identify and address cost overruns, eliminate unnecessary spending, and maximize the value of your cloud investment. Streamline your cloud operations and drive business success with our Cloud Cost Optimization Insights dashboard.',
    iconUrl: 'cost.png',
    features: [
      'Easy to compare/view multiple subscriptions in a single view',
      'Reserved Instances Savings View: View savings achieved by your reservations and view what cost would have been if no reservation was applied for easy rebilling',
      'Orphaned Resources: Save the cost of unused resources, prevent misconfiguration and simplify operations.',
      'Logical grouping for quick filtering of costs by group',
      'Easy switch between Amortized or Actual cost',
      'Cost Recommendations from Azure Advisor',
      'Historical progression view of your cost',
      'Tag view: explore your cost by tags',
      'Interactive drilldown to get more detailed insights',
      'Reservation insights on utilization and coverage',
      'Reservation Recommendations from Azure Advisor',
      'Anomaly detection: identity unusual cloud spend',
    ],
    relatedProducts: [],
    screenshotUrls: ['cost_laptop.png'],
    accessRights: {
      rbacRoles: [
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Reader',
        },
        {
          id: '3224651a-e643-4408-9a06-230aa6a276eb',
          displayName: 'Cost Management Reader',
        },
        {
          id: '9ebab615-0c80-4a11-a732-6955a88e256c',
          displayName:
            '(Optional, but required for reservation insights) Reservations Reader',
        },
      ],
      azureAdRoles: [],
    },
  },
  {
    id: 'a21a5ca9-0b2f-49ad-a773-9954a18b7c4a',
    name: 'Governance',
    material_icon: 'policy',
    shortName: 'Governance',
    shortDescription:
      'Gain insights into cloud changes, including subscription management, policy exemptions, RBAC updates, and automatic remediation.',
    description:
      'The Governance dashboard provides an overview of changes and configurations across your cloud environment, including subscription management, policy exemptions,RBAC adjustments, VNet updates, and automatic remediation.',
    iconUrl: 'governance.png',
    features: [
      'Details available upon request. Please contact our support team for more information.',
    ],
    relatedProducts: [],
    screenshotUrls: ['governance_laptop.png'],
    accessRights: {
      rbacRoles: [
        {
          id: 'acdd72a7-3385-48ef-bd42-f606fba81ae7',
          displayName: 'Reader',
        },
      ],
      azureAdRoles: [],
    },
  },
];
